<template>
  <div>
    <!-- 关联抽屉 -->
    <el-drawer :visible.sync="drawerShow" :size="1200" @close="drawerClose()">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ drawerTitle }}</div>
        <div class="drawer-title-btns">

        </div>
      </div>
      <div style="padding: 20px;">
        <div class="tabbar">
          <div
            v-for="item in tabList" :key="item.id"
            :class="tabClass(item.id)"
            @click="changeTab(item.id)">
            {{ item.label }}
          </div>
        </div>
        <div v-if="tabActive === '0'">
          <el-form ref="formRef" :model="addEditForm" label-width="160px" size="small" :rules="rules">
            <el-form-item label="班级名称" prop="name">
              <el-input style="width:400px;" v-model="addEditForm.name" placeholder="请输入班级名称"></el-input>
            </el-form-item>
            <el-form-item label="班级起止时间设置" prop="radio">
              <el-radio-group v-model="addEditForm.radio">
                <el-radio label="1">
                  <span style="padding-right: 40px">固定时间</span>
                </el-radio>
                <el-radio label="2">长期</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="班级起止时间设置" prop="date" v-if="addEditForm.radio == 1">
              <el-date-picker
                  v-model="addEditForm.date"
                  type="daterange"
                  range-separator="至"
                  value-format="timestamp"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </el-form-item>
            <el-form-item>
              <el-button size="small" type="primary" @click="doOk()">确定</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div v-if="tabActive === '1'">
          <div style="display: flex;justify-content: space-between;">
            <Input style="width: 300px;" v-model="studentSearch.keyword" placeholder="搜索学生姓名、ID、手机号" clearable search
            :enter-button="true" @on-search="getStudentList()" />
            <el-button type="primary" size="small" @click=addStudent()>添加学生</el-button>
          </div>
          <el-table style="margin-top: 20px;" :data="studentList" size="small" border>
            <el-table-column label="ID" prop="user.id" width="80"></el-table-column>
            <el-table-column label="昵称" prop="user.nickname" show-overflow-tooltip></el-table-column>
            <el-table-column label="真实姓名" prop="user.realname" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column label="手机号" prop="mobile" show-overflow-tooltip></el-table-column> -->
            <!-- <el-table-column label="状态" prop="status">
              <template v-slot="{ row }">
                <div>{{ studentStatusObj[row.status] }}</div>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="星级评分" prop="score"></el-table-column> -->
            <el-table-column label="注册和更新时间" show-overflow-tooltip>
              <template v-slot="{ row }">
                <div>{{ util.timeFormatter(new Date(+row.user.created_at * 1000), 'yy-MM-dd hh:mm') }}</div>
                <div>{{ util.timeFormatter(new Date(+row.user.updated_at * 1000), 'yy-MM-dd hh:mm') }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template v-slot="{ row }">
                <el-button size="small" type="danger" @click="removeStudent(row)">移出</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagin class="pagination" :pagination="studentPagination" @currentChange="studentCurrentChange"
            @sizeChange="studentSizeChange" />
        </div>
        <div v-if="tabActive === '2'">
          <div style="display: flex; justify-content: space-between;">
            <Input style="width: 300px;" v-model="teacherSearch.keyword" placeholder="搜索老师姓名、ID、手机号" clearable search
            :enter-button="true" @on-search="getTeacherList()" />
            <el-button type="primary" size="small" @click="addTeacher()">添加老师</el-button>
          </div>
          <el-table style="margin-top: 20px;" :data="teacherList" size="small" border>
            <el-table-column label="ID" prop="user.id" width="80"></el-table-column>
            <!-- <el-table-column label="所属租户" prop="site.name" width="100"></el-table-column> -->
            <!-- <el-table-column label="专业岗位" prop="siteUser.department"></el-table-column> -->
            <el-table-column label="昵称" prop="user.nickname" show-overflow-tooltip></el-table-column>
            <el-table-column label="真实姓名" prop="user.realname" show-overflow-tooltip></el-table-column>
            <!-- <el-table-column label="手机号" prop="mobile" show-overflow-tooltip></el-table-column> -->
            <!-- <el-table-column label="状态" prop="status">
              <template v-slot="{ row }">
                <div>{{ teacherStatusObj[row.user.status] }}</div>
              </template>
            </el-table-column> -->
            <!-- <el-table-column label="星级评分" prop="score"></el-table-column> -->
            <el-table-column label="注册和更新时间" show-overflow-tooltip>
              <template v-slot="{ row }">
                <div>{{ util.timeFormatter(new Date(+row.user.created_at * 1000), 'yy-MM-dd hh:mm') }}</div>
                <div>{{ util.timeFormatter(new Date(+row.user.updated_at * 1000), 'yy-MM-dd hh:mm') }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template v-slot="{ row }">
                <el-button size="small" type="danger" @click="removeTeacher(row)">移出</el-button>
              </template>
            </el-table-column>
          </el-table>
          <Pagin class="pagination" :pagination="teacherPagination" @currentChange="teacherCurrentChange"
            @sizeChange="teacherSizeChange" />
        </div>
        <div v-if="tabActive === '3'">
          <el-button type="primary" size="small" @click="addCourse()">添加关联课程</el-button>
          <el-table style="margin-top: 20px;" :data="courseList" size="small" border>
            <el-table-column label="ID" prop="course.id" width="80"></el-table-column>
            <el-table-column label="课程名称" prop="course.name" show-overflow-tooltip></el-table-column>
            <el-table-column label="注册和更新时间" show-overflow-tooltip>
              <template v-slot="{ row }">
                <div>{{ util.timeFormatter(new Date(+row.course.created_at * 1000), 'yy-MM-dd hh:mm') }}</div>
                <div>{{ util.timeFormatter(new Date(+row.course.updated_at * 1000), 'yy-MM-dd hh:mm') }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" width="120">
              <template v-slot="{ row }">
                <el-button size="small" type="danger" @click="removeCourse(row)">移出</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-drawer>

    <!-- 关联学生抽屉 -->
    <el-drawer :visible.sync="addStudentDrawer" :size="1200">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">关联学生</div>
      </div>
      <div style="padding: 20px;">
        <div style="display: flex; justify-content: space-between;margin-bottom: 20px;">
          <el-button type="primary" size="small" @click="selectionAss()">批量关联</el-button>
          <Input v-model="allStudentSearch.keyword" style="width: 300px;" @on-enter="getAllStudentList" @on-search="getAllStudentList"
          placeholder="模糊搜索" clearable search :enter-button="true" />
        </div>
        <el-table
          :data="allStudentList"
          border
          size="small"
          @selection-change="handleSelectionChangeStudent"
          style="width: 100%">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            prop="id"
            label="Id"
            width="80">
          </el-table-column>
          <el-table-column
            prop="nickname"
            :label="$t('user_mygroups_user_nickname')">
            <template v-slot="{row}">
              <div>{{ row.nickname + '(' + row.id + ')' }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            width="180">
            <template v-slot="{row}">
              <div>{{ util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd hh:mm') }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="150">
            <template v-slot="{row}">
              <div v-if="row.isBind">已关联</div>
              <el-button v-else @click="assStudent(row)" type="primary" size="small" :loading="studentLoading && row.id===studentId">关联</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagin class="pagination" :pagination="allStudentPagination" @currentChange="allStudentCurrentChange" @sizeChange="allStudentSizeChange"/>
      </div>
    </el-drawer>
    <!-- 关联老师抽屉 -->
    <el-drawer :visible.sync="addTeacherDrawer" :size="1200">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">关联老师</div>
      </div>
      <div style="padding: 20px;">
        <div style="display: flex; justify-content: space-between;margin-bottom: 20px;">
          <el-button type="primary" size="small" @click="selectionAssTeacher()">批量关联</el-button>
          <Input v-model="allTeacherSearch.keyword" style="width: 300px;" @on-enter="getAllTeacherList()" @on-search="getAllTeacherList()"
          placeholder="模糊搜索" clearable search :enter-button="true" />
        </div>
        <el-table
          :data="allTeacherList"
          border
          size="small"
          @selection-change="handleSelectionChangeTeacher"
          style="width: 100%">
          <el-table-column
            type="selection"
            width="55">
          </el-table-column>
          <el-table-column
            prop="id"
            label="Id"
            width="80">
          </el-table-column>
          <el-table-column
            prop="nickname"
            :label="$t('user_mygroups_user_nickname')">
            <template v-slot="{row}">
              <div>{{ row.nickname + '(' + row.id + ')' }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="创建时间"
            width="180">
            <template v-slot="{row}">
              <div>{{ util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd hh:mm') }}</div>
            </template>
          </el-table-column>
          <el-table-column
            label="操作"
            width="150">
            <template v-slot="{row}">
              <div v-if="row.isBind">已关联</div>
              <el-button v-else @click="assTeacher(row)" type="primary" size="small" :loading="teacherLoading && row.id===teacherId">关联</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagin class="pagination" :pagination="allTeacherPagination" @currentChange="allTeacherCurrentChange" @sizeChange="allTeacherSizeChange"/>
      </div>
    </el-drawer>
    <!-- 关联课程抽屉 -->
    <el-drawer :visible.sync="addCourseDrawer" :size="1200">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">关联课程</div>
      </div>
      <div style="padding: 20px;">
        <el-table :data="allCourseList" border size="small">
          <el-table-column label="ID" prop="id" width="80"></el-table-column>
          <el-table-column label="课程名称" prop="name"></el-table-column>
          <el-table-column label="创建时间" width="120" show-overflow-tooltip>
            <template v-slot="{ row }">
              <div>{{ util.timeFormatter(new Date(+row.created_at * 1000), 'yy-MM-dd hh:mm') }}</div>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="80">
            <template v-slot="{row}">
              <div v-if="row.isBind">已关联</div>
              <el-button v-else type="primary" size="small" @click="assCourse(row)" :loading="courseLoading && courseId===row.id">关联</el-button>
            </template>
          </el-table-column>
        </el-table>
        <Pagin class="pagination" :pagination="addCoursePagination" @currentChange="addCourseCurrentChange" @sizeChange="addCourseSizeChange"/>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { ref, watch, computed, reactive, getCurrentInstance } from "vue";
import util from "@/utils/tools.js";
import Pagin from "@/components/myPagin.vue";
import { MessageBox } from "element-ui";

// 组件实例相当于this
const instance = getCurrentInstance();
// 定义props
const props = defineProps({
  show: {
    type: Boolean,
    default: false
  },
  isSuper: {
    type: Boolean,
    default: false
  },
  classData: {
    type: Object,
    default:()=>{
      return {}
    }
  }
});
// 定义emits
const emit = defineEmits(["close"])

let radio = ref('1');


// 抽屉可见性
const drawerShow = ref(false);
// 抽屉标题
const drawerTitle = computed(() =>{
  if(props.classData.id){
    return `编辑班级-${props.classData.name}`;
  }else{
    return "创建班级";
  }
});
// 监听抽屉打开关闭
watch(() => props.show, (newVal) => {
  if (newVal && props.classData.id) {
    getStudentList();
    getTeacherList();
    getCourseList();
    addEditForm.value.group_id = props.classData.id;
    addEditForm.value.name=props.classData.name;
    if(props.classData.start_time > 0){
      addEditForm.value.radio = '1';
      addEditForm.value.date=[props.classData.start_time * 1000,props.classData.end_time * 1000];
    }else{
      addEditForm.value.radio = '2';
      addEditForm.value.date=[];
    }
  }
  drawerShow.value = newVal;
});
// 关闭抽屉
function drawerClose() {
  addEditForm.value = {
    name: "",
    group_id: "",
    date:[],
    radio:'1'
  };
  formRef?.value?.resetFields();
  tabActive.value="0";
  drawerShow.value = false;
  emit("close");
}


const tabActive = ref("0");
const tabList = [
  {
    id: "0",
    label: "基本信息"
  },
  {
    id: "1",
    label: "关联学生"
  },
  {
    id: "2",
    label: "关联老师"
  },
  {
    id: "3",
    label: "关联课程"
  }
];
function changeTab(id) {
  if(!props.classData.id) return;
  tabActive.value = id;
}
function tabClass(id){
  // :class="tabActive == item.id ? 'tabActive' : ''"
  let str="";
  if(tabActive.value===id){
    str+="tabActive";
  }
  if(!props.classData.id && id!=="0"){
    str+="disable";
  }
  return str;
}


// 基本信息相关代码
const addEditForm = ref({
  name: "",
  group_id: "",
  date:[],
  radio:'1'
});
const formRef = ref(null);
const rules = {
  name: [
    { required: true, message: "请输入班级名称", trigger: "blur" }
  ],
  date:[
    { type: 'array',required: radio.value  == '1' ? true : false, message: "请选择班级起止时间", trigger: "change" }
  ]
}
function doOk() {
  formRef.value.validate(valid => {
    if (valid) {
      let params = {

        name: addEditForm.value.name,
        start_time:addEditForm.value.date[0]/1000,
        end_time:addEditForm.value.date[1]/1000
      }
      if(addEditForm.value.radio == '1'){
        params.start_time = addEditForm.value.date[0]/1000;
        params.end_time = addEditForm.value.date[1]/1000;
      }else{
        params.start_time = '0';
        params.end_time = '0';
      }
      if (addEditForm.value.group_id) {
        params.group_id = addEditForm.value.group_id;
        instance.proxy.api.user.groupUpdate(params).then(() => {
          instance.proxy.$Message.success("编辑成功");
        });
      } else {
        instance.proxy.api.user.groupCreate(params).then(() => {
          instance.proxy.$Message.success("创建班级成功");
          drawerClose();
        });
      }
    }
  });
}


// 关联学生相关代码
const studentList = ref([]);
const studentSearch = reactive({
  keyword: ""
});
const studentStatusObj = ref({});
const studentPagination = reactive({
  page: 1,
  pageSize: 10,
  total: 0
});
function studentCurrentChange(page) {
  studentPagination.page = page;
  getStudentList();
}
function studentSizeChange(size) {
  studentPagination.pageSize = size;
  getStudentList();
}
function getStudentList() {
  instance.proxy.api.user.groupUserList({
    group_id: props.classData.id,
    orderby:"type,id",
    sort:"desc,desc",
    type:"0",
    ...studentSearch,
    ...studentPagination
  }).then((res) => {
    console.log("关联的学生数据",res);
    studentStatusObj.value = res.group_user_types;
    studentList.value = res.list;
    studentPagination.total = Number(res.count);
  });
}
const addStudentDrawer=ref(false);
const allStudentList=ref([]);
const allStudentSearch=reactive({
  keyword:""
});
const allStudentPagination=reactive({
  page:1,
  pageSize:10,
  total:0
});
function allStudentCurrentChange(page){
  allStudentPagination.page=page;
  getAllStudentList();
}
function allStudentSizeChange(size){
  allStudentPagination.pageSize=size;
  getAllStudentList();
}
function addStudent(){
  addStudentDrawer.value=true;
  getAllStudentList();
}
function getAllStudentList(){
  instance.proxy.api.user.userList({
    // group_id:props.classData.id,
    // is_filter_group:"1",
    ...allStudentSearch,
    ...allStudentPagination,
    // role:"role_student"
  }).then(res=>{
    res.list.forEach(item=>{
      const flag= studentList.value.find(e=>e.user.id===item.id);
      if(flag){
        item.isBind=true;
      }
    });
    allStudentList.value=res.list;
    allStudentPagination.total=Number(res.count);
    console.log("所有学生数据",res);
  });
}
const studentLoading=ref(false);
const studentId=ref("");
function assStudent(row){
  studentLoading.value=true;
  studentId.value=row.id;
  instance.proxy.api.user.groupUserCreate({
    group_id:props.classData.id,
    user_id:row.id
  }).then(() => {
    getStudentList();
    getAllStudentList();
    studentLoading.value=false;
    instance.proxy.$Message.success("关联学生成功");
  });
}
function removeStudent(row){
  MessageBox.confirm('确定移出当前学生吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    instance.proxy.api.user.groupUserDelete({
      group_id:props.classData.id,
      user_id:row.user.id
    }).then(() => {
      getStudentList();
      getAllStudentList();
      instance.proxy.$Message.success('移出学生成功');
    });
  });
}
const selectionStudentList=ref([]);
function handleSelectionChangeStudent(list){
  selectionStudentList.value=list;
}
function selectionAss(){
  if(!selectionStudentList.value.length){
    instance.proxy.$Message.error("请选择学生");
    return;
  }
  instance.proxy.api.user.groupUserCreate({
    group_id:props.classData.id,
    user_id:selectionStudentList.value?.map(item=>item.id)?.join(",")
  }).then(() => {
    getStudentList();
    getAllStudentList();
    instance.proxy.$Message.success("关联学生成功");
  });
}


// 关联老师相关代码
const teacherList = ref([]);
const teacherSearch = reactive({
  keyword: ""
});
const teacherStatusObj = ref({});
const teacherPagination = reactive({
  page: 1,
  pageSize: 10,
  total: 0
});
function teacherCurrentChange(page) {
  teacherPagination.page = page;
  getTeacherList();
}
function teacherSizeChange(size) {
  teacherPagination.pageSize = size;
  getTeacherList();
}
function getTeacherList() {
  instance.proxy.api.user.groupUserList({
    group_id: props.classData.id,
    orderby:"type,id",
    sort:"desc,desc",
    type:"1",
    ...teacherSearch,
    ...teacherPagination
  }).then((res) => {
    console.log("关联的老师数据",res);
    teacherStatusObj.value = res.group_user_types;
    teacherList.value = res.list;
    teacherPagination.total = Number(res.count);
  });
}
const addTeacherDrawer=ref(false);
const allTeacherList=ref([]);
const allTeacherSearch=reactive({
  keyword:""
});
const allTeacherPagination=reactive({
  page:1,
  pageSize:10,
  total:0
});
function allTeacherCurrentChange(page){
  allTeacherPagination.page=page;
  getAllTeacherList();
}
function allTeacherSizeChange(size){
  allTeacherPagination.pageSize=size;
  getAllTeacherList();
}
function addTeacher(){
  addTeacherDrawer.value=true;
  getAllTeacherList();
}
function getAllTeacherList(){
  instance.proxy.api.user.userList({
    // group_id:props.classData.id,
    // is_filter_group:"1",
    ...allTeacherSearch,
    ...allTeacherPagination,
    // role:"role_teacher"
  }).then(res=>{
    res.list.forEach(item=>{
      const flag= teacherList.value.find(e=>e.user.id===item.id);
      if(flag){
        item.isBind=true;
      }
    });
    allTeacherList.value=res.list;
    allTeacherPagination.total=Number(res.count);
    console.log("所有老师数据",res);
  });
}
const teacherLoading=ref(false);
const teacherId=ref("");
function assTeacher(row){
  teacherLoading.value=true;
  teacherId.value=row.id;
  instance.proxy.api.user.groupUserCreate({
    group_id:props.classData.id,
    user_id:row.id,
    type:"1"
  }).then(() => {
    getTeacherList();
    getAllTeacherList();
    teacherLoading.value=false;
    instance.proxy.$Message.success("关联老师成功");
  });
}
function removeTeacher(row){
  MessageBox.confirm('确定移出当前老师吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    instance.proxy.api.user.groupUserDelete({
      group_id:props.classData.id,
      user_id:row.user.id,
      type:"1"
    }).then(() => {
      getTeacherList();
      getAllTeacherList();
      instance.proxy.$Message.success('移出老师成功');
    });
  });
}
const selectionTeacherList=ref([]);
function handleSelectionChangeTeacher(list){
  selectionTeacherList.value=list;
}
function selectionAssTeacher(){
  if(!selectionTeacherList.value.length){
    instance.proxy.$Message.error("请选择老师");
    return;
  }
  instance.proxy.api.user.groupUserCreate({
    group_id:props.classData.id,
    user_id:selectionTeacherList.value?.map(item=>item.id)?.join(","),
    type:"1"
  }).then(() => {
    getTeacherList();
    getAllTeacherList();
    instance.proxy.$Message.success("关联老师成功");
  });
}


// 关联课程代码
const courseList = ref([]);
// const courseStatus = ref({});
const coursePagination = reactive({
  page: 1,
  pageSize: 10,
  total: 0
});
function getCourseList() {
  instance.proxy.api.course.courseGroupList({
    group_id: props.classData.id
  }).then(res => {
    courseList.value=res.list;
    coursePagination.total=res.list.length;
    console.log("关联的课程信息", res);
  });
}
// 获取全部课程
const addCourseDrawer=ref(false);
const allCourseList=ref([]);
const addCoursePagination=reactive({
  page:1,
  size:10,
  total:0
});
function addCourseCurrentChange(page){
  addCoursePagination.page=page;
  addCourse();
}
function addCourseSizeChange(size){
  addCoursePagination.pageSize=size;
  addCourse();
}
const courseLoading=ref(false);
const courseId=ref("");
// 点击关联按钮
function addCourse() {
  getAllCourseList();
  addCourseDrawer.value=true;
}
// 获取所有课程数据
function getAllCourseList(){
  instance.proxy.api.course.courseList({
    user_id: "-1",
    ...addCoursePagination
  }).then((res) => {
    console.log("所有课程数据", res);
    addCoursePagination.total=Number(res.count);
    res.list.forEach(item=>{
      const flag=courseList.value.find(e=>e.course.id===item.id);
      if(flag){
        item.isBind=true;
      }else{
        item.isBind=false;
      }
    });
    allCourseList.value = res.list;
  });
}
watch([allCourseList,courseList],(newVal)=>{
  newVal[0].forEach(item=>{
    const flag=newVal[1].find(e=>e.course.id===item.id);
    if(flag){
      item.isBind=true;
    }else{
      item.isBind=false;
    }
  });
});
// 关联课程
function assCourse(row){
  courseLoading.value=true;
  courseId.value=row.id;
  instance.proxy.api.course.classBindCourse({
    group_id:props.classData.id,
    course_id:row.id
  }).then(()=>{
    getCourseList();
    addCourse();
    courseLoading.value=false;
    instance.proxy.$Message.success("关联成功");
  });
}
// 移出课程
function removeCourse(row){
  MessageBox.confirm('确定移出当前课程吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    instance.proxy.api.course.removeCourse({
      course_id:row.course.id,
      group_id:props.classData.id
    }).then(()=>{
      getCourseList();
      getAllCourseList();
      instance.proxy.$Message.success("移出成功");
    });
  });
}

</script>

<style lang="scss" scoped>
.tabbar {
  border-bottom: 1px solid #dcdee2;
  display: flex;
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
  margin-bottom: 20px;

  >div {
    margin-right: 20px;
    cursor: pointer;
    position: relative;
  }

  .tabActive {
    color: #4a79ff;

    &::after {
      content: "";
      display: block;
      height: 4px;
      width: 100%;
      background-color: #4a79ff;
      position: absolute;
      left: 0;
      bottom: -20px;
    }
  }

  .disable {
    color: #bdc3ca !important;
  }
}

.disable{
  color: #bdc3ca;
}

.btns {
  text-align: end;
}

.pagination {
  margin-top: 20px;
  text-align: end;
}
</style>
