<template>
  <div class="class-manage">
    <div class="class-manage-top" :class="isManager ? 'bor' : ''">
      <div class="tab" >
        <template v-for="item in statusList" >
          <p :class="curTabId == item.id ? 'active' : ''" v-if="item.id != 2" :key="item.id" @click="changeTab(item.id)">{{item.name}}({{item.val}})</p>
        </template>
      </div>
      <el-button type="primary" size="small" @click="create" >创建班级</el-button>
    </div>
    <el-table :data="tableData" border size="small">
      <el-table-column label="ID" prop="id" width="80"></el-table-column>
      <el-table-column label="班级名称" prop="name" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="开课时间" width="100">
        <template v-slot="{ row }">
          {{ row.start_time > 0 ? util.timeFormatter(new Date(+row.start_time * 1000), 'yyyy-MM-dd') : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="结课时间" width="100">
        <template v-slot="{ row }">
          {{ row.end_time > 0 ? util.timeFormatter(new Date(+row.end_time * 1000), 'yyyy-MM-dd') : '-' }}
        </template>
      </el-table-column>
      <el-table-column label="学生数量" width="80" prop="count"></el-table-column>
      <el-table-column label="教师数量" prop="teacher_count" width="80"></el-table-column>
      <el-table-column label="课程数量" width="80" prop="course_count"></el-table-column>
      <el-table-column label="状态" width="80">
        <template v-slot="{ row }">
          {{ classStatus[row.status] }}
        </template>
      </el-table-column>
      <el-table-column label="创建时间" width="160">
        <template v-slot="{ row }">
          {{ util.timeFormatter(new Date(+row.created_at * 1000), 'yyyy-MM-dd hh:mm') }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200">
        <template v-slot="{ row }">
          <el-button size="small" type="primary" @click="invite(row)" v-if="row.status != 4">邀请</el-button>
          <el-button size="small" icon="el-icon-edit" type="warning" @click="association(row)"></el-button>
          <el-button v-if="row.is_public!=='1'" size="small" icon="el-icon-delete" type="danger" @click="dele(row)"></el-button>
        </template>
      </el-table-column>
    </el-table>
    <Pagin
      style="margin-top: 20px;text-align: end;"
      :pagination="pagination"
      @currentChange="currentChange"
      @sizeChange="sizeChange"
    />
    <AssData :show="associationDrawer" :classData="classData" @close="associationDrawerClose"/>

    <el-drawer :visible.sync="inviteDrawerShow" :size="800">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">创建邀请链接</div>
      </div>
      <div style="padding: 20px;">
        <InviteModal v-if="inviteDrawerShow" :groupId="classData.id" :rolesList="rolesList"
        :expireList="expireList" :siteId="$store.state.user.userInfo.site.id"
        :siteName="$store.state.user.userInfo.site.name"></InviteModal>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import util from '@/utils/tools.js';
import Pagin from "@/components/myPagin";
import AssData from "./associatedData.vue"
import { MessageBox } from 'element-ui';
import InviteModal from '@/components/inviteModal.vue';
export default {
  name: "ClassManage",
  props:{
    refresh:{
      type:Number,
      default:0
    },
    isUser:{
      type:Boolean,
      default:false
    },
    isManager:{
      type:Boolean,
      default:false
    }
    // status:{
    //   type:String,
    //   default:''
    // }
  },
  created() {
    this.getForm();
    this.getClassList();
    this.getTeacherList();
    this.getInvitationForm();
  },
  components: {
    Pagin,
    AssData,
    InviteModal
  },
  data() {
    return {
      util,
      inviteDrawerShow:false,
      associationDrawer:false,
      drawerShow: false,
      // 分页器数据
      pagination: {
        page: 1,
        pageSize: 10,
        total: 0
      },
      // 列表数据
      tableData: [],
      // 班级状态数据
      classStatus: {},
      // 表单数据
      addEditForm: {
        name: "",
        group_id: "",
      },
      teacherList: [],
      rules: {
        name: [
          { required: true, message: "请输入班级名称", trigger: "blur" }
        ]
      },
      classData:{},
      expireList:[],
      rolesList:[],
      statusList:[],
      curTabId:'1',
    }
  },
  methods: {
    getForm(){
      let params = {
        with_group_count:'1'
      };
      if(this.isManager){
        params.user_id="-1";
      }
      this.api.user.groupForm(params).then((res)=>{
        this.statusList = [];
        for(let name in res.statuses){
          let arr = res.group_status_count_list.filter((item)=>{
            return item.status == name;
          })
          let obj = {
            name:res.statuses[name],
            id:name
          };
          if(arr.length){
            obj.val = arr[0].count;
          }else{
            obj.val = 0;
          }
          this.statusList.push(obj)
        }
      })
    },
    changeTab(id){
      this.curTabId = id;
      this.pagination.page = 1;
      this.getClassList();
    },
    create(){
      this.$emit('create')
    },
    // 获取班级列表数据
    getClassList() {
      const { pagination } = this;
      this.api.user.groupList({
        ...pagination,
        user_id: this.isUser?"-1":"",
        status:this.curTabId,
      }).then(res => {
        this.classStatus = res.statuses;
        this.tableData = res.list || [];
        pagination.total = Number(res.count);
      });
    },
    // 筛选条件改变
    // changeSearch(data, name) {
    //   const { pagination,searchForm } = this;
    //   searchForm[name] = data;
    //   pagination.page = 1;
    //   this.getClassList();
    // },
    currentChange(page) {
      const { pagination } = this;
      pagination.page = page;
      this.getClassList();
    },
    sizeChange(size) {
      const { pagination } = this;
      pagination.pageSize = size;
      this.getClassList();
    },
    edit(row) {
      const { addEditForm } = this;
      addEditForm.group_id = row.id;
      this.getGroupDetail();
      this.drawerShow = true;
    },
    getGroupDetail() {
      const { addEditForm } = this;
      this.api.user.groupDetail({
        group_id: addEditForm.group_id
      }).then((res) => {
        // addEditForm.teacher_user_ids = res.group.teacher_list.map(item => item.user_id);
        addEditForm.name = res.group.name;
        this.teacherList = [];
      });
    },
    doOk() {
      const { addEditForm } = this;
      this.$refs.formRef.validate(valid => {
        if (valid) {
          if (addEditForm.group_id) {
            this.api.user.groupUpdate(addEditForm).then(() => {
              this.$Message.success("编辑成功");
              this.getClassList();
              this.drawerShow = false;
            });
          } else {
            this.api.user.groupCreate(addEditForm).then(() => {
              this.$Message.success("创建班级成功");
              this.getClassList();
              this.drawerShow = false;
            });
          }
        }
      });
    },
    dele(row) {
      MessageBox.confirm('确定删除当前班级吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.api.user.groupDelete({
          group_id: row.id,
          user_id: "-1"
        }).then(() => {
          this.$Message.success("删除成功");
          this.getClassList();
        });
      });
    },
    getTeacherList(query) {
      let params = {
        pageSize: 10,
        page: 1,
        keyword: query,
        role: 'role_teacher'
      };
      this.api.production.userList(params).then((res) => {
        this.teacherList = res.list;
      });
    },
    remoteMethod(query) {
      if (query !== '') {
        this.getTeacherList(query);
      } else {
        this.teacherList = [];
      }
    },
    drawerClose() {
      this.addEditForm = {
        name: "",
        group_id: "",
        teacher_user_ids: []
      }
      this.$refs.formRef.resetFields();
    },
    association(row) {
      this.classData=row;
      this.associationDrawer=true;
    },
    associationDrawerClose(){
      this.getClassList();
      this.associationDrawer=false;
    },
    getInvitationForm() {
      this.api.site.invitationForm().then((res) => {
        this.expireList = [];
        for (let name in res.expire_types) {
          this.expireList.push({
            label: res.expire_types[name],
            value: name
          });
        }
        this.rolesList = [];
        for (let name in res.invitation_roles) {
          this.rolesList.push({
            label: res.invitation_roles[name],
            value: name
          })
        }
      });
    },
    invite(row){
      this.classData=row;
      this.inviteDrawerShow=true;
    }
  },
  watch:{
    refresh(){
      this.getClassList();
    },
    status(){
      console.log('212121212')
      this.getClassList();
    }
  }
}
</script>

<style lang="scss" scoped>
.class-manage {
  //.class-manage-top {
  //  display: flex;
  //  justify-content: space-between;
  //  align-items: center;
  //  margin-bottom: 10px;
  //  position: relative;
  //}
  .class-manage-top {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #EEEEEE;
    .tab{
      display: flex;
      justify-content: flex-start;
      align-items: center;
      >p{
        margin-right: 30px;
        padding-bottom: 10px;
        height: 100%;
        font-size: 16px;
        color: #666666;
        cursor: pointer;
        &.active{
          font-weight: bold;
          color: #4A79FF;
          border-bottom: 4px solid #5782FF;
        }
      }
    }
  }
  .bor{
    border-bottom: none;
    .tab{
      >p{
        padding: 0 20px;
        height: 42px;
        background: #F6F7FA;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        &.active{
          font-weight: bold;
          color: #FFFFFF;
          background: #4A79FF;
        }
      }


    }
  }

  .search-box {

    .search-item {
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name {
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }

      .cont {
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values {
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div {
            padding: 5px 10px;
            cursor: pointer;
          }

          >div.active {
            background-color: #2d8cf0;
            color: #FFFFFF;
            border-radius: 4px;
          }
        }

        .more {
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;

          .more-icon {
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
}
</style>
